<template>
  <b-row>
    <b-button @click="formatRDPs()"> TEST</b-button>
    <b-col md="12">
      <validation-observer ref="simpleRules">
        <form-wizard
          color="#7367F0"
          :title="null"
          :subtitle="null"
          shape="square"
          finish-button-text="Review"
          back-button-text="Previous"
          class="mb-3"
          @on-complete="verifyModal"
          ref="form"
        >
          <!-- region Selection -->
          <tab-content
            title="Region Group"
            :before-change="validationRegionGroup"
          >
            <validation-observer ref="regionGroup" tag="form">
              <b-col md="6">
                <validation-provider
                  #default="{ errors }"
                  name="Region Group"
                  rules="required"
                >
                  <b-form-group
                    label="Region Group Name"
                    label-for="regiongroup-name"
                  >
                    <v-select
                      placeholder="Add Options"
                      :clearable="false"
                      :options="regionGroup"
                      label="name"
                      :state="errors.length > 0 ? false : null"
                      v-model="newSite.regionGroupID"
                    />

                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false : null"
                    >
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </validation-observer>
          </tab-content>

          <!-- Site Details -->
          <tab-content title="Site Details" :before-change="validationSite">
            <validation-observer ref="siteCreate" tag="form">
              <b-row>
                <b-col md="4">
                  <b-form-group label="SiteID" label-for="site-siteid">
                    <validation-provider
                      rules="required|validSiteID"
                      name="SiteID"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newSite.siteID"
                        id="site-siteid"
                        placeholder="SiteID"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Name" label-for="site-name">
                    <validation-provider
                      rules="required|min:3|max:23|noSpecial"
                      name="SiteName"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newSite.name"
                        id="site-name"
                        placeholder="Site Name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="4">
                  <b-form-group label="Site LSD" label-for="site-lsd">
                    <validation-provider
                      rules="required|min:10|max:14"
                      name="LSD"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="newSite.lsd"
                        id="site-lsd"
                        placeholder="Site LSD Name"
                        min="4"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Site Latitude" label-for="site-lat">
                    <validation-provider
                      rules="required|validLat"
                      name="Lat"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="
                          newSite.geoJSON[0].features[0].geometry[0]
                            .coordinates[1]
                        "
                        id="site-lat"
                        placeholder=""
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Longitude" label-for="site-long">
                    <validation-provider
                      rules="required|validLng"
                      name="Long"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="
                          newSite.geoJSON[0].features[0].geometry[0]
                            .coordinates[0]
                        "
                        id="site-long"
                        placeholder=""
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-row>
                    <b-col>
                      <CreateMap
                        :sites="siteLocation()"
                        :regions="regionLocation()"
                        :markerControl="true"
                        :draggable="true"
                        :geoControl="true"
                        @handlemarkerupdate="updateMarker"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- RDPS -->
          <tab-content
            title="Remote Desktops"
            :before-change="validationRDPCreate"
          >
            <validation-observer ref="rdpCreate" tag="form">
              <b-row
                v-for="(rdp, index) in newSite.rdps"
                v-bind:key="rdp.id"
                v-bind:item="rdp"
                ref="row"
              >
                <b-col md="3">
                  <b-form-group label="RDP Name" label-for="remote-name">
                    <validation-provider
                      rules="noSpecial|required"
                      name="rdp name"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="rdp.name"
                        :id="'remote-name-' + index"
                        placeholder="Connection Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="IP Address" label-for="remote-ip">
                    <validation-provider
                      rules="required"
                      name="rdp ip"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="rdp.ip"
                        :id="'remote-ip-' + index"
                        placeholder="IP Address"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Type" label-for="rdp-protocol">
                    <validation-provider
                      rules="required"
                      name="rdp protocol"
                      v-slot="{ errors }"
                    >
                      <v-select
                        placeholder="Select Type"
                        :id="'rdp-protocol-' + index"
                        :reduce="(option) => option.protocol"
                        :options="createConnection"
                        :disabled="createConnection.disabled"
                        :state="errors.length > 0 ? false : null"
                        v-model="rdp.protocol"
                        @option:selected="changedProtocol(rdp.protocol, index)"
                        class="border rounded"
                      />
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Additional Attributes"
                    label-for="rdp-parameters"
                  >
                    <validation-provider rules="required" name="rdp parameters">
                      <v-select
                        v-model="rdp.parameters"
                        multiple
                        placeholder="Choose Connection Type First"
                        label="group"
                        :close-on-select="false"
                        :disabled="!rdp.protocol"
                        :deselectFromDropdown="true"
                        :clearable="true"
                        class="border rounded"
                        :options="rdpParameterList(rdp.protocol)"
                      >
                      </v-select>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                  v-for="(parameter, index) in rdp.parameters"
                  :key="index"
                >
                  <hr />
                  <b-col md="12">
                    <h3>{{ parameter.group }}</h3>
                  </b-col>
                  <b-col
                    md="3"
                    v-for="(attribute, index) in parameter.parameters"
                    :key="index"
                    class="mb-1"
                  >
                    <b-form-group :label="attribute.description">
                      <validation-provider
                        :rules="attribute.validation"
                        :name="attribute.description"
                        v-slot="{ errors }"
                      >
                        <b-form-input
                          :type="attribute.type"
                          v-if="
                            attribute.type === 'text' ||
                            attribute.type === 'password' ||
                            attribute.type === 'number'
                          "
                          :placeholder="attribute.default"
                          v-model="attribute.value"
                        />

                        <b-form-checkbox
                          v-if="attribute.type === 'boolean'"
                          v-model="attribute.value"
                          :placeholder="attribute.default"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col lg="2" md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeRDP(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col md="12" class="ml-1 mr-1">
                  <hr />
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12">
                  <div class="text-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatRDP"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>Add New</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Cameras -->
          <tab-content title="Cameras" :before-change="validationCameraCreate">
            <validation-observer ref="cameraCreate" tag="form">
              <b-row
                v-for="(camera, index) in newSite.cameras"
                v-bind:key="camera.id"
                v-bind:item="camera"
                ref="row"
              >
                <b-col md="3">
                  <b-form-group label="Name" label-for="camera-name">
                    <validation-provider
                      rules="noSpecial|required"
                      name="camera name"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        type="text"
                        placeholder="Camera Name"
                        v-model="camera.name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="URL" label-for="camera-url">
                    <validation-provider
                      rules="url|max:70|required"
                      name="camera url"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="camera-url"
                        v-model="camera.url"
                        type="text"
                        placeholder="URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Image URL" label-for="camera-img">
                    <validation-provider
                      rules="url|required"
                      name="camera img"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        id="camera-img"
                        v-model="camera.img"
                        type="text"
                        placeholder="Camera Image URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeCamera(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <div class="text-center">
                    <b-button
                      class="align-items-center"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatCamera"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      Add New
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>

          <!-- Quick Links -->
          <tab-content title="Quick Links" :before-change="validationQLCreate">
            <validation-observer ref="QLCreate" tag="form">
              <b-row
                v-for="(quicklink, index) in newSite.quicklinks"
                v-bind:key="quicklink.id"
                v-bind:item="quicklink"
                ref="row"
              >
                <b-col md="3">
                  <b-form-group label="Quick Link Name" label-for="QL-name">
                    <validation-provider
                      rules="noSpecial|required"
                      name="QL name"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="quicklink.name"
                        id="QuickLink-name"
                        placeholder="QL Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    label="Quick Link Short Name"
                    label-for="QL-shortname"
                  >
                    <validation-provider
                      rules="noSpecial|required|min:1|max:2"
                      name="QuickLink Shortname"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="quicklink.shortname"
                        id="QL-shortname"
                        placeholder="Max 2 characters"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Quick Link URL" label-for="QL-url">
                    <validation-provider
                      rules="url|required"
                      name="QL URL"
                      v-slot="{ errors }"
                    >
                      <b-form-input
                        v-model="quicklink.url"
                        id="QL-url"
                        placeholder="QL URL"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="10">
                  <b-form-group label="Quick Link Color" label-for="QL-color">
                    <validation-provider
                      name="Quick Link Color"
                      v-slot="{ errors }"
                    >
                      <div class="d-flex justify-content-start flex-wrap">
                        <div
                          @click="selectColor(quicklink, 'primary', index)"
                          :id="'primary-' + index"
                          class="text-center bg-primary colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 bg-lighten-1"
                        ></div>
                        <div
                          @click="selectColor(quicklink, 'secondary', index)"
                          :id="'secondary-' + index"
                          class="text-center bg-secondary colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor(quicklink, 'success', index)"
                          :id="'success-' + index"
                          class="text-center bg-success colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor(quicklink, 'warning', index)"
                          :id="'warning-' + index"
                          class="text-center bg-warning colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor(quicklink, 'danger', index)"
                          :id="'danger-' + index"
                          class="text-center bg-danger colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center mr-1 ml-50 my-1"
                        ></div>
                        <div
                          @click="selectColor(quicklink, 'info', index)"
                          :id="'info-' + index"
                          class="text-center bg-info colors-container rounded text-white width-50 height-50 d-flex align-items-center justify-content-center my-1 ml-50"
                        ></div>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col md="2" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeQL(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Delete</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12">
                  <div class="text-center">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      @click="repeatQL"
                    >
                      <feather-icon icon="PlusIcon" class="mr-25" />
                      <span>Add New</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </validation-observer>
    </b-col>

    <!-- review modal -->
    <b-modal
      ref="verify-modal"
      title="Review"
      centered
      cancel-title="Close"
      ok-title="Publish"
      cancel-variant="outline-secondary"
      bg-variant="transparent"
      @ok="saveAndPublish"
    >
      <b-row>
        <b-col md="12" xl="12">
          <b-card>
            <h3 class="font-weight-normal text-center">Region Group</h3>
            <h4 class="font-weight-normal text-center">
              {{ newSite.regionGroupID.name }}
            </h4>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12" xl="12">
          <b-card>
            <h3 class="font-weight-normal text-center">Site Name</h3>
            <h4 class="font-weight-normal text-center">
              {{ newSite.name }}
            </h4>
            <hr />
            <dl class="row">
              <dt class="col-sm-12">
                <b>siteID:</b> <br />{{ newSite.siteID }}
              </dt>
            </dl>
            <dl class="row">
              <dt class="col-sm-12"><b>LSD:</b><br />{{ newSite.lsd }}</dt>
            </dl>
            <dl class="row">
              <dt class="col-sm-12">
                <b>Latitude:</b><br />{{
                  newSite.geoJSON[0].features[0].geometry[0].coordinates[1]
                }}
              </dt>
            </dl>
            <dl class="row">
              <dt class="col-sm-12">
                <b>Longitude:</b><br />{{
                  newSite.geoJSON[0].features[0].geometry[0].coordinates[0]
                }}
              </dt>
            </dl>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="newSite.cameras.length">
        <b-col md="12" xl="12">
          <b-card>
            <h3 class="font-weight-normal text-center">Cameras</h3>
            <b-table responsive :items="newSite.cameras" bordered>
              <template #cell(ICON)="data" class="text-center">
                <div class="text-center">
                  <feather-icon :icon="data.value" />
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="newSite.rdps.length">
        <b-col md="12" xl="12">
          <b-card>
            <h3 class="font-weight-normal text-center">
              Remote Desktop Connections
            </h3>
            <b-table stacked :items="reviewModalRDPS()" bordered>
              <template #cell(ICON)="data" class="text-center">
                <div class="text-center">
                  <feather-icon :icon="data.value" />
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-if="newSite.quicklinks.length">
        <b-col md="12" xl="12">
          <b-card>
            <h3 class="font-weight-normal text-center">Quick Links</h3>
            <b-table stacked :items="newSite.quicklinks" bordered>
              <template #cell(ICON)="data" class="text-center">
                <div class="text-center">
                  <feather-icon :icon="data.value" />
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button variant="danger" @click="cancel()"> Cancel </b-button>
        <b-button variant="secondary" @click="saveWithoutPublishing()">
          Save Without Publishing
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button variant="primary" @click="ok()"> Publish </b-button>
      </template>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'
import { regionGroup } from '@/services/regionGroup.service.js'
import { site } from '@/services/site.service.js'
import CreateMap from '@/components/maps/MapComponent.vue'
import {
  BButton,
  BCol,
  BRow,
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BContainer,
  BTable,
  BFormCheckbox
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
  validSiteID,
  min,
  max,
  noSpecial,
  url,
  validLng,
  validLat
} from '@validations'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'CreateSiteWizard',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    CreateMap,
    BButton,
    BCol,
    BRow,
    BCard,
    BCardTitle,
    BTable,
    BFormInput,
    BContainer,
    BFormGroup,
    BFormCheckbox,

    BFormInvalidFeedback: ValidationObserver,
    FormWizard,
    TabContent,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    VueGoodTable
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      required,
      min,
      max,
      validLng,
      validLat,
      validSiteID,
      noSpecial,
      url,
      drawControls: {
        draw: {
          polygon: false,
          marker: false
        }
      },
      regionGroup: [],
      disableAttributes: false,
      newConnection: [],
      createConnection: [
        { label: 'RDP', protocol: 'rdp' },
        { label: 'VNC', protocol: 'vnc' }
      ],
      selected: '',
      addCamera: 1,
      addRDP: 1,
      addQL: 1,
      newSite: {
        name: '',
        siteID: '',
        lsd: '',
        cameras: [
          {
            name: '',
            url: '',
            img: ''
          }
        ],
        rdps: [
          {
            name: '',
            ip: '',
            protocol: '',
            parameters: []
          }
        ],
        quicklinks: [
          {
            name: '',
            shortname: '',
            url: '',
            class: 'primary'
          }
        ],
        geoJSON: [
          {
            type: 'FeatureCollection',
            features: [
              { type: 'Feature', geometry: [{ coordinates: [-110, 50] }] }
            ]
          }
        ],
        regionGroupID: {
          name: null,
          geoJSON: [
            {
              type: 'FeatureCollection',
              features: [
                {
                  id: 'b523f2da6cbf5e1f91bbc982fad2423e',
                  type: 'Feature',
                  geometry: {
                    coordinates: [
                      [
                        [-117.14923375015555, 55.994772042410744],
                        [-98.22864537569176, 55.62930433133306],
                        [-100.51369494301066, 53.11582438667381],
                        [-119.71346626686625, 53.9336316990877],
                        [-117.14923375015555, 55.994772042410744]
                      ]
                    ],
                    type: 'Polygon'
                  }
                }
              ]
            }
          ]
        },
        groupName: '',
        status: 'Published'
      }
    }
  },
  computed: {},
  methods: {
    changedProtocol(protocol, index) {
      if (protocol === 'rdp') {
        this.newSite.rdps[index].protocol = 'rdp'
        this.newSite.rdps[index].parameters = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Port of the server',
                default: '3389',
                value: '3389'
              },
              {
                name: 'security',
                type: 'text',
                validation: 'required',
                required: false,
                description: 'Security of the server',
                default: '',
                value: 'any'
              },
              {
                name: 'ignore-cert',
                type: 'boolean',
                validation: 'required',
                required: false,
                description: 'Ignore certificate',
                default: '',
                value: true
              }
            ]
          }
        ]
      } else {
        this.newSite.rdps[index].protocol = 'vnc'
        this.newSite.rdps[index].parameters = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Port of the server',
                default: '5900',
                value: '5900'
              }
            ]
          }
        ]
      }
    },
    selectColor(qlink, color, index) {
      // clear currently selected styles
      let orgRef = qlink.class + '-' + index
      document.getElementById(orgRef).classList.remove('bg-lighten-1')
      // select new color
      qlink.class = color
      let elementId = color + '-' + index
      document.getElementById(elementId).classList.add('bg-lighten-1')
    },
    // Fetch Data For Region Groups and Return Response To Groups
    async getAllRegionGroups() {
      this.loading = true
      try {
        let data = await regionGroup.getAllRegionGroups()
        this.regionGroup = data.data
      } catch (e) {
        this.error = e.message
      } finally {
        this.loading = false
      }
    },

    // Repeat Camera Function for multiple lines and push data into array
    repeatCamera() {
      this.newSite.cameras.push({
        name: '',
        url: '',
        img: ''
      })
    },
    // Repeat RDP Function for multiple lines and push data into array
    repeatRDP() {
      this.newSite.rdps.push({
        name: '',
        ip: '',
        protocol: '',
        parameters: []
      })
    },

    repeatQL() {
      this.newSite.quicklinks.push({
        name: '',
        url: '',
        class: 'primary',
        shortname: ''
      })
    },
    // Remove Camera if button is pressed
    removeCamera(index) {
      this.newSite.cameras.splice(index, 1)
    },
    // Remove Camera if button is pressed
    removeRDP(index) {
      this.newSite.rdps.splice(index, 1)
    },
    removeQL(index) {
      this.newSite.quicklinks.splice(index, 1)
    },

    // MAP SAVE METHODS
    siteLocation() {
      let data = [this.newSite]
      return data
    },
    regionLocation() {
      let data = [this.newSite.regionGroupID]
      return data
    },
    dragLocation(dragLocation) {
      this.newSite.geoJSON[0].features[0].geometry[0].coordinates = dragLocation
    },
    updatePolygon(updatePolygon) {
      return (this.newSite.geoJSON[0].features[0].geometry[0].coordinates = [
        -110, 50
      ])
    },
    updateMarker(updateMarker) {
      if (event.type === 'mousedown') {
        this.$emit('drag-end')
        this.newSite.geoJSON[0].features[0].geometry[0].coordinates = [-110, 50]
      } else {
        this.newSite.geoJSON[0].features[0].geometry[0].coordinates = [
          updateMarker.lng,
          updateMarker.lat
        ]
      }
    },

    // Validate Site Creation and check if exists
    validationSite() {
      return new Promise((resolve, reject) => {
        this.$refs.siteCreate.validate().then((success) => {
          if (success) {
            site
              .checkUnique({
                name: this.newSite.name,
                lsd: this.newSite.lsd,
                siteID: this.newSite.siteID
              })
              .then((response) => {
                let err = false
                if (response.data.data.lsd === false) {
                  this.$refs.siteCreate.setErrors({
                    LSD: ['This LSD is already taken.']
                  })
                  err = true
                }
                if (response.data.data.name === false) {
                  this.$refs.siteCreate.setErrors({
                    SiteName: ['This Site Name is already taken.']
                  })
                  err = true
                }
                if (response.data.data.siteID === false) {
                  this.$refs.siteCreate.setErrors({
                    SiteID: ['This siteID is already taken.']
                  })
                  err = true
                }

                if (err === true) {
                  reject()
                } else {
                  resolve(true)
                }
              })
          } else {
            reject()
          }
        })
      })
    },
    // Validate RegionGroup before moving forward
    validationRegionGroup() {
      return new Promise((resolve, reject) => {
        this.$refs.regionGroup.validate().then((success) => {
          if (success) {
            setTimeout(() => {
              window.dispatchEvent(new Event('resize'))
            }, 10)
            this.newSite.geoJSON[0].features[0].geometry[0].coordinates = [
              -110, 50
            ]
            resolve(true)
          } else if (
            this.newSite.regionGroupID.name.length === 1 ||
            this.newSite.regionGroupID.name === ''
          ) {
            reject()
          } else {
            reject()
          }
        })
      })
    },
    // Validate CameraGroup before moving forward
    validationCameraCreate() {
      return new Promise((resolve, reject) => {
        this.$refs.cameraCreate.validate().then((success) => {
          if (success) {
            resolve(true)
          } else if (
            this.newSite.cameras.length === 1 &&
            this.newSite.cameras[0].name === '' &&
            this.newSite.cameras[0].url === '' &&
            this.newSite.cameras[0].img === ''
          ) {
            this.newSite.cameras = []
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    // Validate CameraGroup before moving forward
    validationQLCreate() {
      return new Promise((resolve, reject) => {
        this.$refs.QLCreate.validate().then((success) => {
          if (success) {
            resolve(true)
          } else if (
            this.newSite.quicklinks.length === 1 &&
            this.newSite.quicklinks[0].name === '' &&
            this.newSite.quicklinks[0].shortname === '' &&
            this.newSite.quicklinks[0].class === 'primary' &&
            this.newSite.quicklinks[0].url === ''
          ) {
            this.newSite.quicklinks = []
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    //Validate RDP Create before moving forward
    validationRDPCreate() {
      return new Promise((resolve, reject) => {
        this.$refs.rdpCreate.validate().then((success) => {
          if (success) {
            resolve(true)
          } else if (
            (this.newSite.rdps.length === 1 &&
              this.newSite.rdps[0].name === '' &&
              this.newSite.rdps[0].ip === '' &&
              this.newSite.rdps[0].protocol === '') ||
            this.newSite.rdps[0].parameters.length === 0
          ) {
            this.newSite.rdps = []
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    //Show confirmation modal
    verifyModal() {
      this.$refs['verify-modal'].show()
    },
    reviewModalRDPS() {
      return this.newSite.rdps.map((rdp) => {
        return {
          Name: rdp.name,
          IP: rdp.ip,
          Protocol: rdp.protocol
        }
      })
    },
    formatRDPs() {
      const rdpStructure = this.newSite.rdps
      const restructureRDP = rdpStructure.map((rdp) => {
        const getRDPGroups = rdp.parameters.flatMap((group) => {
          const getRDPParameters = group.parameters.flatMap((parameters) => {
            return {
              name: parameters.name,
              value: parameters.value,
              group: group.group
            }
          })
          // filter through getRDPParameters and if value is empty remove it
          const filteredRDPParameters = getRDPParameters.filter(
            (parameter) => parameter.value !== ''
          )
          return {
            group: group.group,
            parameters: filteredRDPParameters
          }
        })
        // filter through groups and if value is empty remote it
        const filteredRDPGroups = getRDPGroups.filter(
          (group) => group.parameters.length > 0
        )
        // join the group and parameters into a single object to return to submit
        return {
          name: rdp.name,
          ip: rdp.ip,
          protocol: rdp.protocol,
          parameters: filteredRDPGroups
        }
      })
      return restructureRDP
    },
    saveWithoutPublishing() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // set region Group
          this.newSite.groupName = this.newSite.regionGroupID.name
          // set status to unpublished
          this.newSite.status = 'Unpublished'
          // format newSite.rdps parameters to be saved
          this.newSite.rdps = this.formatRDPs()
          // eslint-disable-next-line
          site.createSite(this.newSite).then((response) => {
            if (response.status) {
              this.$refs['verify-modal'].hide()
              this.successAlert()
            } else {
              window.Bus.instance.$emit('pageDanger', {
                header: 'ERROR',
                detail: 'Could not create new Site'
              })
            }
          })
        }
      })
    },
    // Validate form again before submitting
    saveAndPublish() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // set region Group
          this.newSite.groupName = this.newSite.regionGroupID.name
          // set status to published
          this.newSite.status = 'Published'
          this.newSite.rdps = this.formatRDPs()
          // eslint-disable-next-line
          site.createSite(this.newSite).then((response) => {
            if (response.status) {
              this.successAlert()
            } else {
              window.Bus.instance.$emit('pageDanger', {
                header: 'ERROR',
                detail: 'Could not create new Site'
              })
            }
          })
        }
      })
    },
    successAlert() {
      this.$swal({
        title: 'Success!',
        text: 'Site created successfully.',
        icon: 'success',
        confirmButtonText: 'Dismiss',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
      // send event to siteManager to refresh site list

      this.newSite = {
        name: '',
        siteID: '',
        lsd: '',
        cameras: [
          {
            name: '',
            url: '',
            img: ''
          }
        ],
        rdps: [
          {
            name: '',
            url: '',
            ip: '',
            protocol: '',
            parameters: []
          }
        ],
        quicklinks: [
          {
            name: '',
            shortname: '',
            url: '',
            class: 'primary'
          }
        ],
        regionGroupID: {
          name: null,
          geoJSON: [
            {
              type: 'FeatureCollection',
              features: [
                {
                  id: '',
                  type: 'Feature',
                  geometry: {
                    coordinates: [],
                    type: 'Polygon'
                  }
                }
              ]
            }
          ]
        },
        geoJSON: [
          {
            type: 'FeatureCollection',
            features: [
              { type: 'Feature', geometry: [{ coordinates: [-110, 50] }] }
            ]
          }
        ],
        groupName: '',
        status: 'Unpublished'
      }
      this.$refs.form.reset()
      this.$refs.simpleRules.reset()
      window.Bus.instance.$emit('updateSiteList')
    },
    rdpParameterList(type) {
      if (type === 'rdp') {
        const rdpParameters = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Port of the server',
                default: '3389',
                value: '3389'
              }
            ]
          },
          {
            group: 'Authentication',
            parameters: [
              {
                name: 'username',
                type: 'text',
                validation: 'max:50',
                required: false,
                description: 'Username for the connection',
                default: 'admin',
                value: ''
              },
              {
                name: 'password',
                type: 'password',
                validation: 'max:60',
                required: false,
                description: 'Password for the connection',
                default: 'admin',
                value: ''
              }
            ]
          },
          {
            group: 'Display',
            parameters: [
              {
                name: 'read-only',
                type: 'boolean',
                required: false,
                description: 'Read only mode',
                default: false,
                value: ''
              },
              {
                name: 'swap-red-blue',
                type: 'boolean',
                required: false,
                description: 'Swap red and blue',
                default: false,
                value: ''
              },
              {
                name: 'cursor',
                type: 'boolean',
                required: false,
                description: 'Show mouse cursor',
                default: true,
                value: ''
              },
              {
                name: 'color-depth',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Color depth',
                default: '8',
                value: ''
              }
            ]
          },
          {
            group: 'Clipboard',
            parameters: [
              {
                name: 'clipboard-encoding',
                type: 'text',
                validation: 'max:20',
                required: false,
                description: 'Clipboard encoding',
                default: 'CP437',
                value: ''
              },
              {
                name: 'disable-copying',
                type: 'boolean',
                required: false,
                description: 'Disable copying',
                default: false,
                value: ''
              },
              {
                name: 'disable-paste',
                type: 'boolean',
                required: false,
                description: 'Disable pasting',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'Screen Recording',
            parameters: [
              {
                name: 'recording-path',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'Recording path',
                default: 'C:\\Temp\\',
                value: ''
              },
              {
                name: 'recording-name',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'Recording name',
                default: 'recording',
                value: ''
              },
              {
                name: 'recording-exclude-output',
                type: 'boolean',
                required: false,
                description: 'Exclude output',
                default: false,
                value: ''
              },
              {
                name: 'recording-exclude-mouse',
                type: 'boolean',
                required: false,
                description: 'Exclude mouse',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'SFTP',
            parameters: [
              {
                name: 'sftp-hostname',
                type: 'text',
                validation: 'ip',
                required: false,
                description: 'SFTP hostname',
                default: 'localhost',
                value: ''
              },
              {
                name: 'sftp-host-key',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP host key',
                default: 'ssh-rsa',
                value: ''
              },
              {
                name: 'sftp-username',
                type: 'text',
                validation: 'alpha_dash',
                required: false,
                description: 'SFTP username',
                default: 'admin',
                value: ''
              },
              {
                name: 'sftp-password',
                type: 'password',
                validation: 'max:60',
                required: false,
                description: 'SFTP password',
                default: 'admin',
                value: ''
              },
              {
                name: 'sftp-private-key',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP private key',
                default: '',
                value: ''
              },
              {
                name: 'sftp-passphrase',
                type: 'text',
                validation: 'max:60',
                required: false,
                description: 'SFTP passphrase',
                default: '',
                value: ''
              },
              {
                name: 'sftp-root-directory',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP root directory',
                default: '/',
                value: ''
              },
              {
                name: 'sftp-directory',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'SFTP directory',
                default: '',
                value: ''
              }
            ]
          },
          {
            group: 'Audio',
            parameters: [
              {
                name: 'disable-audio',
                type: 'boolean',
                required: false,
                description: 'Disable audio',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'Custom',
            parameters: [
              {
                name: 'custom-parameter',
                type: 'text',
                validation: 'max:255',
                required: false,
                description: 'Custom parameter',
                default: '',
                value: ''
              }
            ]
          }
        ]
        return rdpParameters
      }
      if (type === 'vnc') {
        const vncParameters = [
          {
            group: 'Port',
            parameters: [
              {
                name: 'port',
                type: 'number',
                required: false,
                description: 'Port of the server',
                default: '5900',
                value: '5900'
              }
            ]
          },
          {
            group: 'Authentication',
            parameters: [
              {
                name: 'username',
                type: 'text',
                required: false,
                description: 'Username of the server',
                default: 'admin',
                value: ''
              },
              {
                name: 'password',
                type: 'password',
                required: false,
                description: 'Password of the server',
                default: 'admin',
                value: ''
              }
            ]
          },
          {
            group: 'Display',
            parameters: [
              {
                name: 'read-only',
                type: 'boolean',
                required: false,
                description: 'Read only mode',
                default: 'false',
                value: ''
              },
              {
                name: 'swap-red-blue',
                type: 'boolean',
                required: false,
                description: 'Swap red and blue',
                default: 'false',
                value: ''
              },
              {
                name: 'cursor',
                type: 'boolean',
                required: false,
                description: 'Show mouse cursor',
                default: 'true',
                value: ''
              },
              {
                name: 'color-depth',
                type: 'number',
                required: false,
                description: 'Color depth',
                default: '8',
                value: ''
              },
              {
                name: 'width',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Width',
                default: '1920',
                value: '1920'
              },
              {
                name: 'height',
                type: 'number',
                validation: 'between:1,65535',
                required: false,
                description: 'Width',
                default: '1080',
                value: '1080'
              }
            ]
          },
          {
            group: 'Clipboard',
            parameters: [
              {
                name: 'clipboard-encoding',
                type: 'text',
                required: false,
                description: 'Clipboard encoding',
                default: 'CP437',
                value: ''
              },
              {
                name: 'disable-copying',
                type: 'boolean',
                required: false,
                description: 'Disable copying',
                default: false,
                value: ''
              },
              {
                name: 'disable-paste',
                type: 'boolean',
                required: false,
                description: 'Disable pasting',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'Screen Recording',
            parameters: [
              {
                name: 'recording-path',
                type: 'text',
                required: false,
                description: 'Recording path',
                default: 'C:\\Temp\\',
                value: ''
              },
              {
                name: 'recording-name',
                type: 'text',
                required: false,
                description: 'Recording name',
                default: 'recording',
                value: ''
              },
              {
                name: 'recording-exclude-output',
                type: 'boolean',
                required: false,
                description: 'Exclude output',
                default: false,
                value: ''
              },
              {
                name: 'recording-exclude-mouse',
                type: 'boolean',
                required: false,
                description: 'Exclude mouse',
                default: false,
                value: ''
              }
            ]
          },
          {
            group: 'SFTP',
            parameters: [
              {
                name: 'sftp-hostname',
                type: 'text',
                required: false,
                description: 'SFTP hostname',
                default: 'localhost',
                value: ''
              },
              {
                name: 'sftp-host-key',
                type: 'text',
                required: false,
                description: 'SFTP host key',
                default: 'ssh-rsa',
                value: ''
              },
              {
                name: 'sftp-username',
                type: 'text',
                required: false,
                description: 'SFTP username',
                default: 'admin'
              },
              {
                name: 'sftp-password',
                type: 'text',
                required: false,
                description: 'SFTP password',
                default: 'admin'
              },
              {
                name: 'sftp-private-key',
                type: 'text',
                required: false,
                description: 'SFTP private key',
                default: ''
              },
              {
                name: 'sftp-passphrase',
                type: 'text',
                required: false,
                description: 'SFTP passphrase',
                default: ''
              },
              {
                name: 'sftp-root-directory',
                type: 'text',
                required: false,
                description: 'SFTP root directory',
                default: '/'
              },
              {
                name: 'sftp-directory',
                type: 'text',
                required: false,
                description: 'SFTP directory',
                default: ''
              }
            ]
          },
          {
            group: 'Audio',
            parameters: [
              {
                name: 'disable-audio',
                type: 'boolean',
                required: false,
                description: 'Disable audio',
                default: 'false'
              }
            ]
          },
          {
            group: 'Custom',
            parameters: [
              {
                name: 'custom-parameter',
                type: 'text',
                required: false,
                description: 'Custom parameter',
                default: '',
                value: ''
              }
            ]
          }
        ]
        return vncParameters
      }
    }
  },
  async mounted() {
    this.getAllRegionGroups()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.bg-lighten-1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
}

.map-container {
  height: 30vh;
  width: 100%;
}
</style>
