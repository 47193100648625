<template>
  <div>
    <!-- Camera Sidebar -->
    <b-sidebar
      id="sidebar-camera"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Cameras</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="cams.length > 0">
          <b-card
            v-for="camera in cams"
            v-bind:key="camera._id"
            v-bind:item="camera"
            class="mt-2 ml-1 mr-1"
          >
            <b-card-title>{{ camera.name }}</b-card-title>
            <h5>URL:</h5>
            <p>{{ camera.url }}</p>
            <h5>Image URL:</h5>
            <p>{{ camera.img }}</p>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No Cameras</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- RDP Sidebar -->
    <b-sidebar
      id="sidebar-rdps"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">RDPs</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="rdps.length > 0">
          <b-card
            v-for="rdp in rdps"
            v-bind:key="rdp._id"
            v-bind:item="rdp"
            class="mt-2 ml-1 mr-1"
          >
            <b-card-title>{{ rdp.name }}</b-card-title>
            <h5>IP:</h5>
            <p>{{ rdp.ip }}</p>
            <h5>Type:</h5>
            <p>{{ rdp.protocol }}</p>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No RDPs</h4>
        </div>
      </template>
    </b-sidebar>

    <!-- QuickLink Sidebar -->
    <b-sidebar
      id="sidebar-qlinks"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5 class="mb-0">Quick Links</h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Body -->
        <div v-if="qlinks.length > 0">
          <b-card
            v-for="qlink in qlinks"
            v-bind:key="qlink._id"
            v-bind:item="qlink"
            class="mt-2 ml-1 mr-1"
          >
            <div class="row">
              <div class="col-3">
                <b-avatar
                  size="4rem"
                  :variant="'' + qlink.class + ''"
                  :text="qlink.shortname"
                >
                </b-avatar>
              </div>
              <div class="col-9">
                <b-card-title>{{ qlink.name }}</b-card-title>
                <h5>URL:</h5>
                <p>{{ qlink.url }}</p>
              </div>
            </div>
          </b-card>
        </div>
        <div v-else class="m-2 align-text-center">
          <h4>No QuickLinks</h4>
        </div>
      </template>
    </b-sidebar>

    <div class="row">
      <div class="col-12">
        <div class="vue-form-wizard mb-3 md">
          <div class="d-flex justify-content-between flex-wrap mr-1 ml-1">
            <div class="d-flex align-items-center mb-1 mt-1">
              <span class="">Show</span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20', '50', '100']"
                class="mx-1"
              />
              <span class="text-nowrap">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-toggle.collapse-1
                  variant="primary"
                  @click="refreshView"
                >
                  Create Site
                </b-button>
              </span>
            </div>
            <div>
              <div class="custom-search d-flex justify-content-right mt-1 mb-1">
                <div class="d-flex align-items-center">
                  <span class="mr-1">Search</span>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </div>
            </div>
          </div>

          <b-collapse id="collapse-1" class="ml-1 mr-1">
            <CreateSiteWizard></CreateSiteWizard>
          </b-collapse>

          <vue-good-table
            :columns="columns"
            :rows="rows"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Action -->
              <span v-if="props.column.field === 'actions'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25 table-icon"
                      />
                    </template>
                    <b-dropdown-item
                      @click="handleRouteToEditor(props.row.siteID)"
                    >
                      <feather-icon icon="Edit2Icon" class="mr-50 table-icon" />
                      <span>Edit</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="showDeleteModal(props.row.siteID, props.row.name)"
                    >
                      <feather-icon icon="TrashIcon" class="mr-50 table-icon" />
                      <span>Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>
              <!-- Column: Name -->
              <span v-else-if="props.column.field === 'name'">
                <div
                  @click="handleRouteToEditor(props.row.siteID)"
                  class="link"
                >
                  {{ props.row.name }}
                </div>
              </span>
              <!-- Column: Cameras -->
              <span v-else-if="props.column.field === 'cameras'">
                {{ props.row.cameras.length }}
                <eye-icon
                  v-if="props.row.cameras.length > 0"
                  @click="showCamerasInSidebar(props.row.cameras)"
                  class="table-icon"
                />
              </span>
              <!-- Column: RDPs -->
              <span v-else-if="props.column.field === 'rdps'">
                {{ props.row.rdps.length }}
                <eye-icon
                  v-if="props.row.rdps.length > 0"
                  @click="showRDPsInSidebar(props.row.rdps)"
                  class="table-icon"
                />
              </span>
              <!-- Column: QuickLinks -->
              <span v-else-if="props.column.field === 'quicklinks'">
                {{ props.row.quicklinks.length }}
                <eye-icon
                  v-if="props.row.quicklinks.length > 0"
                  @click="showQuickLinksInSidebar(props.row.quicklinks)"
                  class="table-icon"
                />
              </span>
              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- Pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap mb-1 ml-1"> Show</span>

                  <b-form-select
                    v-model="pageLength"
                    :options="['5', '10', '20', '50', '100']"
                    class="mx-1 mb-1"
                    @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-1 mr-1"
                    @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>

    <!-- Deletion Modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Delete Site"
      ok-title="Delete"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <h6>Warning</h6>
      <p>Please enter in the name of the Site to confirm deletion. <br /></p>
      <span
        >Name:
        <h6>{{ siteToDelete.name }}</h6></span
      >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Name"
          label-for="name-input"
          invalid-feedback="Name Must Match Region Group Name"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BPagination,
  BFormInput,
  BFormSelect,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BCollapse,
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BFormGroup,
  BCardTitle,
  BSidebar,
  BButton,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// Import Icons
import { EyeIcon, MoreVerticalIcon } from 'vue-feather-icons'
// import api
import { site } from '@/services/site.service.js'
// import Mixins
import { heightTransition } from '@core/mixins/ui/transition'
import prepareQueryParamsMixin from '@/mixins/prepareQueryParamsMixin'
import prepareFetchParamsMixin from '@/mixins/prepareFetchParamsMixin'

// Import Table
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

// import validation for editors
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

// import Site Creation Wizard
import CreateSiteWizard from './CreateSiteWizard.vue'
import FeatherIcon from '../../@core/components/feather-icon/FeatherIcon.vue'

// Declare component
export default {
  name: 'SiteManager',
  mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
    BCollapse,
    BAvatar,
    BButton,
    VBToggle,
    BBadge,
    BFormGroup,
    BSidebar,
    BPagination,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    EyeIcon,
    MoreVerticalIcon,
    CreateSiteWizard,
    VueGoodTable,
    BCard,
    BCardBody,
    BCardText,
    BCardTitle
  },
  directives: {
    FeatherIcon,
    'b-toggle': VBToggle,
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      isSidebarActive: false,
      pageLength: 5,
      columns: [
        {
          label: 'Site Name',
          field: 'name'
        },
        {
          label: 'Region',
          field: 'groupName'
        },
        {
          label: 'Cameras',
          field: 'cameras'
        },
        {
          label: 'RDPS',
          field: 'rdps'
        },
        {
          label: 'Quick Links',
          field: 'quicklinks'
        },
        {
          label: 'Status',
          field: 'status'
        },
        {
          label: 'Actions',
          field: 'actions'
        }
      ],
      rows: [],
      status: [
        {
          1: 'Published',
          2: 'Unpublished'
        },
        {
          1: 'light-success',
          2: 'light-warning'
        }
      ],
      searchTerm: '',
      cams: [],
      rdps: [],
      qlinks: [],
      siteToDelete: {
        name: '',
        id: ''
      },
      name: '',
      nameState: ''
    }
  },
  methods: {
    // //////////////////////////////////// Deletion Modal Controls ////////////////////////////////////////////
    checkFormValidity() {
      const valid = this.name === this.siteToDelete.name ? true : false
      this.nameState = valid
      return valid
    },
    refreshView() {
      // emit event to refresh view
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'))
      }, 20)
    },
    resetModal() {
      this.name = ''
      this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.deleteSite(this.siteToDelete.id)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    showDeleteModal(id, name) {
      this.siteToDelete.name = name
      this.siteToDelete.id = id
      this.$bvModal.show('modal-prevent-closing')
    },

    async deleteSite(id) {
      site
        .deleteSite({ siteID: id })
        .then((res) => {
          this.getAllSites()
          window.Bus.instance.$emit('pageSuccess', {
            header: 'SUCCESS',
            detail: res.message
          })
        })
        .catch((error) => {
          window.Bus.instance.$emit('pageDanger', {
            header: 'ERROR',
            detail: error.message
          })
        })
    },

    ////////////////////////////////////////// Table and SiteBar Controls ////////////////////////////////////////////////
    handleRouteToEditor(id) {
      this.$router.push('/siteeditor/' + id)
    },
    async getAllSites() {
      try {
        site.getAllSites().then((data) => {
          this.rows = data.data.data
        })
      } catch (error) {
        window.Bus.instance.$emit('pageDanger', {
          header: 'ERROR',
          detail: error.response.data
            ? error.response.data.error
            : error.response.statusText
        })
      }
    },
    showCamerasInSidebar(cameras) {
      this.cams = cameras
      this.$root.$emit('bv::toggle::collapse', 'sidebar-camera')
    },
    showRDPsInSidebar(rdps) {
      this.rdps = rdps
      this.$root.$emit('bv::toggle::collapse', 'sidebar-rdps')
    },
    showQuickLinksInSidebar(qlinks) {
      this.qlinks = qlinks
      this.$root.$emit('bv::toggle::collapse', 'sidebar-qlinks')
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Published: 'light-success',
        Unpublished: 'light-warning'
        /* eslint-enable key-spacing */
      }

      return (status) => statusColor[status]
    }
  },
  created: function () {
    let that = this
    window.Bus.instance.$on('updateSiteList', () => {
      // refresh Site List
      that.getAllSites()
      // Minimize Site Creation Wizard
      that.$root.$emit('bv::toggle::collapse', 'collapse-1')
    })
  },
  async mounted() {
    this.getAllSites()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
.table-icon {
  color: $primary;
  cursor: pointer;
}
.align-text-center {
  text-align: center;
}
.link:hover {
  color: $primary;
  cursor: pointer;
  text-decoration: underline;
}
/* The Close Button */
.close {
  margin-right: -3px !important;
  margin-top: -3px !important;
}
</style>
